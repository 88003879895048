import { useCallback, useState } from 'react'
import {
  Button,
  Card,
  IconExport,
  Divider,
  Figure,
  LayoutFixed,
  MenuSecondary,
  Selectbox,
  Subtitle,
  Table,
  Title,
  Tooltip,
} from 'ui'
import { sendData } from 'download'
import { exportPurchasesInfos, getPurchasesInfos } from 'services/monitoring'
import { NotificationManager } from 'services/notifications'
import { formatCents, formatPercents } from 'utils/string'
import { waitForApi } from 'utils/api'
import { AdminNavigation } from '../../components'
import { COLUMNS, MENU_LINKS } from '../@commons'
import styles from './Containers.module.scss'

const EXPORT_SUCCESS = 'Les données ont été exportées'
const EXPORT_ERROR = "Impossible d'exporter les données"

export const FeatureAdminMonitoringPurchases = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [years, setYears] = useState([new Date().getFullYear()])
  const [purchases, setPurchases] = useState([])
  const [ongoing, setOngoing] = useState([])
  const [currentOngoing, setCurrentOngoing] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onDataLoaded = ({ result }) => {
    setYears(result.years.sort().reverse())
    setPurchases(
      mapPurchasesToCells(result.data.purchases, result.data.commission)
    )
    setOngoing(mapOngoingToCells(result.data.ongoing))
    setCurrentOngoing(result.currentOngoing)
  }

  const onExport = () => {
    setLoading(true)

    return waitForApi(exportPurchasesInfos({ year: currentYear }))
      .then(({ result }) => {
        setLoading(false)
        sendData(result.file, 'text/csv', result.name)
        NotificationManager.addSuccess(EXPORT_SUCCESS)
      })
      .catch(() => {
        setError(EXPORT_ERROR)
        setLoading(false)
      })
  }

  const mapPurchasesToCells = (purchases, commission) => [
    ['Produits', ...purchases.products.map(v => formatCents(v, 0))],
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>Services</span>
          <Tooltip annotation>
            Services vendus aux artisans, type Colissimo
          </Tooltip>
        </div>,
        ...purchases.services.map(v => formatCents(v, 0)),
      ],
    },
    {
      cells: ['Total', ...purchases.total.map(v => formatCents(v, 0))],
      highlight: true,
    },
    ['Commission moyenne', ...commission.map(v => formatPercents(v / 100, 2))],
  ]

  const mapOngoingToCells = ongoing => [
    ['Produits', ...ongoing.products.map(v => formatCents(v, 0))],
    ['Services', ...ongoing.services.map(v => formatCents(v, 0))],
    {
      cells: ['Total', ...ongoing.total.map(v => formatCents(v, 0))],
      highlight: true,
    },
  ]

  return (
    <LayoutFixed
      title={`Suivi des achats ${currentYear}`}
      fullWidth={true}
      menu={<MenuSecondary links={MENU_LINKS} />}
      nav={<AdminNavigation title={'Suivi'} />}
      actions={
        <div className={styles.actions}>
          <Button
            light
            icon={<IconExport />}
            onClick={onExport}
            hideLabelOnMobile
          >
            Exporter
          </Button>
          <div className={styles.yearSelector}>
            <Selectbox
              id={'YearSelector'}
              label={'Année'}
              initialValue={currentYear.toString()}
              values={years.map(y => y.toString())}
              width={150}
              onChange={v => setCurrentYear(parseInt(v))}
            />
          </div>
        </div>
      }
      loadData={useCallback(
        async () => getPurchasesInfos(currentYear),
        [currentYear]
      )}
      onDataLoaded={onDataLoaded}
      loading={loading}
      error={error}
    >
      <Title level={1}>Achats {currentYear}</Title>
      <Divider />
      {currentYear === new Date().getFullYear() && (
        <>
          <Subtitle level={2}>Encours à date</Subtitle>
          <Figure
            label={`dont ${formatCents(
              currentOngoing.received,
              0
            )} en attente de paiement et ${formatCents(
              currentOngoing.sent,
              0
            )} en cours de livraison`}
            value={formatCents(currentOngoing.total, 0)}
          />
          <Divider />
          <Divider />
        </>
      )}

      <Subtitle level={2}>Historique des achats</Subtitle>
      <Divider />
      <Table columns={COLUMNS} lines={purchases} condensed />
      <Divider />
      <Divider />
      <Subtitle level={2}>Historique des encours</Subtitle>
      <Divider />
      <Card info light>
        Ce tableau contient un résumé des encours à chaque fin de mois (état
        calculé et figé au moment de la facturation).
      </Card>
      <Divider />
      <Table columns={COLUMNS} lines={ongoing} condensed />
      <Divider />
    </LayoutFixed>
  )
}
