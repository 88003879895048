import { useCallback, useState } from 'react'
import {
  Button,
  Divider,
  IconExport,
  LayoutFixed,
  MenuSecondary,
  Selectbox,
  Subtitle,
  Table,
  Title,
  Tooltip,
} from 'ui'
import { sendData } from 'download'
import { exportSalesInfos, getSalesInfos } from 'services/monitoring'
import { NotificationManager } from 'services/notifications'
import { formatCents, formatNumber } from 'utils/string'
import { waitForApi } from 'utils/api'
import { AdminNavigation } from '../../components'
import { COLUMNS, MENU_LINKS } from '../@commons'
import styles from './Containers.module.scss'

const EXPORT_SUCCESS = 'Les données ont été exportées'
const EXPORT_ERROR = "Impossible d'exporter les données"

export const FeatureAdminMonitoringSales = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [years, setYears] = useState([new Date().getFullYear()])
  const [sales, setSales] = useState([])
  const [boxes, setBoxes] = useState([])
  const [direct, setDirect] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onDataLoaded = ({ result }) => {
    setYears(result.years.sort().reverse())
    setSales(mapSalesToCells(result.data.sales))
    setBoxes(mapBoxesToCells(result.data.boxes))
    setDirect(mapDirectToCells(result.data.direct))
  }

  const onExport = () => {
    setLoading(true)

    return waitForApi(exportSalesInfos({ year: currentYear }))
      .then(({ result }) => {
        setLoading(false)
        sendData(result.file, 'text/csv', result.name)
        NotificationManager.addSuccess(EXPORT_SUCCESS)
      })
      .catch(() => {
        setError(EXPORT_ERROR)
        setLoading(false)
      })
  }

  const mapSalesToCells = sales => [
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>CA théorique</span>
          <Tooltip>
            Ce CA tient compte uniquement des coffrets <b>activés</b>, sans les
            remises commerciales éventuelles ni tout ce qui est géré
            hors-plateforme.
          </Tooltip>
        </div>,
        ...sales.theorical.map(v => formatCents(v, 0)),
      ],
      highlight: true,
    },
    ['Coffrets-Cadeaux', ...sales.box.map(v => formatCents(v, 0))],
    {
      cells: ['dont B2B', ...sales.boxB2B.map(v => formatCents(v, 0))],
      italic: true,
    },
    {
      cells: ['dont B2C', ...sales.boxB2C.map(v => formatCents(v, 0))],
      italic: true,
    },
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>Ventes Directes</span>
          <Tooltip bottom>
            <b>Attention</b> : les ventes-directes contiennent les achats par
            cartes-cadeaux (CA compté en double).
          </Tooltip>
        </div>,
        ...sales.direct.map(v => formatCents(v, 0)),
      ],
    },
  ]

  const mapBoxesToCells = ({ period, month, all }) => [
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>Sur la période</span>
          <Tooltip>
            A lire ainsi : Nombre de coffrets <b>créés</b> durant le mois de{' '}
            <b>janvier</b>.
          </Tooltip>
        </div>,
      ],
      highlight: true,
    },
    ['Créés', ...period.created.map(formatAmount)],
    ['Activés', ...period.activated.map(formatAmount)],
    ['Utilisés', ...period.used.map(formatAmount)],
    ['Périmés', ...period.expired.map(formatAmount)],
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>Coffrets du mois</span>
          <Tooltip>
            A lire ainsi : Nombre de coffrets <b>utilisés</b> à date, parmi ceux
            activés durant le mois de <b>janvier</b>.
          </Tooltip>
        </div>,
      ],
      highlight: true,
    },
    ['Utilisés', ...month.used.map(formatAmount)],
    ['Non-utilisés', ...month.unused.map(formatAmount)],
    ['Non-utilisés illimités', ...month.unusedIllimited.map(formatAmount)],
    ['Périmés', ...month.expired.map(formatAmount)],
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>Cumuls</span>
          <Tooltip>
            A lire ainsi : A fin <b>janvier</b>, nombre de coffrets{' '}
            <b>utilisés</b> sur toute la base de coffrets activés.
          </Tooltip>
        </div>,
      ],
      highlight: true,
    },
    ['Activés', ...all.activated.map(formatAmount)],
    ['Utilisés', ...all.used.map(formatAmount)],
    ['Non-utilisés', ...all.unused.map(formatAmount)],
    ['Non-utilisés illimités', ...all.unusedIllimited.map(formatAmount)],
    ['Périmés', ...all.expired.map(formatAmount)],
  ]

  const mapDirectToCells = ({ sales, aov }) => [
    {
      cells: [
        <div className={styles.withTooltip}>
          <span>Ventes B2C</span>
          <Tooltip>
            <b>Attention</b> : les ventes-directes contiennent les achats par
            cartes-cadeaux (CA compté en double).
          </Tooltip>
        </div>,
        ...sales.map(formatAmount),
      ],
    },
    ['Panier moyen', ...aov.map(v => formatCents(v))],
  ]

  const formatAmount = v => (
    <span>
      {formatCents(v.amount, 0)} <br />
      {formatNumber(v.count, 0)}{' '}
    </span>
  )

  return (
    <LayoutFixed
      title={`Suivi des ventes ${currentYear}`}
      fullWidth={true}
      menu={<MenuSecondary links={MENU_LINKS} />}
      nav={<AdminNavigation title={'Suivi'} />}
      actions={
        <div className={styles.actions}>
          <Button
            light
            icon={<IconExport />}
            onClick={onExport}
            hideLabelOnMobile
          >
            Exporter
          </Button>
          <div className={styles.yearSelector}>
            <Selectbox
              id={'YearSelector'}
              label={'Année'}
              initialValue={currentYear.toString()}
              values={years.map(y => y.toString())}
              width={150}
              onChange={v => setCurrentYear(parseInt(v))}
            />
          </div>
        </div>
      }
      loadData={useCallback(
        async () => getSalesInfos(currentYear),
        [currentYear]
      )}
      onDataLoaded={onDataLoaded}
      loading={loading}
      error={error}
    >
      <div>
        <Title level={1}>Ventes {currentYear}</Title>
        <Divider />
        <Subtitle level={2}>Chiffre d'affaires</Subtitle>
        <Divider />
        <Table columns={COLUMNS} lines={sales} condensed />
        <Divider />
        <Divider />
        <Subtitle level={2}>Coffrets-cadeaux</Subtitle>
        <Divider />
        <Table columns={COLUMNS} lines={boxes} condensed />
        <Divider />
        <Divider />
        <Subtitle level={2}>Ventes-directes</Subtitle>
        <Divider />
        <Table columns={COLUMNS} lines={direct} condensed />
        <Divider />
      </div>
    </LayoutFixed>
  )
}
