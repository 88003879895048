import React from 'react'
import { sendData } from 'download'
import { LayoutFixed, MenuSecondary } from 'ui'
import { NotificationManager } from 'services/notifications'
import { getMonthName } from 'utils/time'
import { exportHistory, getShippingHistory } from 'services/shippings'
import { History } from './components'
import { getMenuLinks } from './@commons'
import { SupplierNavigation } from 'features/suppliers/components'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'

const ERROR_INTERNAL =
  'Une erreur interne est survenue, veuillez recharger la page pour obtenir des données à jour'

class ShippingsHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      result: { history: [], stats: {}, supplier: {} },
    }
  }

  loadData = () => {
    return waitForApi(getShippingHistory({ uniqueKey: this.supplierKey() }))
  }

  onError = () => {
    this.setState({ error: ERROR_INTERNAL, loading: false })
  }

  onExport = (year, month) => {
    this.setState({ loading: true })

    return waitForApi(exportHistory(this.supplierKey(), year, month))
      .then(result => this.onExportSuccess(result, year, month))
      .catch(this.onError)
  }

  onExportSuccess = ({ result }, year, month) => {
    this.setState({ loading: false })

    sendData(
      result.file,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      `IciPresent-${year}-${getMonthName(month)}.xlsx`
    )
    NotificationManager.addSuccess(
      `Les données de ${getMonthName(month)} ${year} ont été exportées`
    )
  }

  supplierKey() {
    return this.props.params.uniqueKey
  }

  render() {
    const {
      error,
      result: { history, supplier, stats },
      loading,
    } = this.state

    return (
      <LayoutFixed
        title="Commandes"
        nav={
          <SupplierNavigation
            supplierKey={this.supplierKey()}
            supplierName={supplier.name}
            title={'Commandes'}
          />
        }
        menu={<MenuSecondary links={getMenuLinks(this.supplierKey(), stats)} />}
        loading={loading}
        error={error}
        loadData={this.loadData}
        onDataLoaded={data => this.setState({ ...data })}
      >
        {history && <History history={history} onExport={this.onExport} />}
      </LayoutFixed>
    )
  }
}

export const FeatureSuppliersShippingsHistory = withRouter(ShippingsHistory)
