import { TextField } from 'ui'

export const getInfoItems = (
  formInfo,
  emailConfirm,
  onFieldChange,
  setFormValidity
) => [
  <TextField
    id={'FormFirstname'}
    label={'Prénom'}
    initialValue={formInfo.firstname}
    required
    autoValidate
    focused
    onChange={firstname => onFieldChange({ firstname })}
  />,
  <TextField
    id={'FormLastname'}
    label={'Nom'}
    initialValue={formInfo.lastname}
    required
    autoValidate
    onChange={lastname => onFieldChange({ lastname })}
  />,
  <TextField
    id={'FormCompany'}
    label={'Entreprise'}
    initialValue={formInfo.company}
    autoValidate
    onChange={company => onFieldChange({ company })}
  />,
  <TextField
    id={'FormPhone'}
    label={'Téléphone (format 0123456789)'}
    type={'tel'}
    initialValue={formInfo.phone}
    required
    autoValidate
    onChange={phone => onFieldChange({ phone })}
  />,
  <TextField
    id={'FormEmail'}
    label={'Email'}
    type={'email'}
    initialValue={formInfo.email}
    required
    autoValidate
    onChange={email => onFieldChange({ email })}
  />,
  <TextField
    id={'FormEmailConfirm'}
    label={"Confirmation de l'email"}
    pattern={formInfo.email}
    initialValue={emailConfirm}
    required
    autoValidate
    onChange={setFormValidity}
  />,
]

export const getStreetItems = (formInfo, onFieldChange) => [
  <TextField
    id={'FormStreet'}
    label={'Adresse (numéro, rue et complément)'}
    initialValue={formInfo.street}
    required
    autoValidate
    onChange={street => onFieldChange({ street })}
  />,
]

export const getAddressItems = (formInfo, onFieldChange) => [
  <TextField
    id={'FormZipCode'}
    label={'Code postal'}
    initialValue={formInfo.zipcode}
    type={'zip_code'}
    required
    autoValidate
    onChange={zipcode => onFieldChange({ zipcode })}
  />,
  <TextField
    id={'FormCity'}
    label={'Ville'}
    initialValue={formInfo.city}
    required
    autoValidate
    onChange={city => onFieldChange({ city })}
  />,
]

export const getCountryItems = (formInfo, onFieldChange) => [
  <TextField
    id={'FormCountry'}
    label={'Pays'}
    initialValue={formInfo.country}
    required
    autoValidate
    onChange={country => onFieldChange({ country })}
  />,
]
