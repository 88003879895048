import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  FormGroup,
  FormSection,
  TextFieldWithDisplay,
  RadioListWithDisplay,
  SelectboxWithDisplay,
  Link,
  TextAreaWithDisplay,
  FileUpload,
  getOrderB2BBadgeProps,
} from 'ui'
import { DELIVERY_METHODS, MESSAGE_TYPES, ADDRESS_TYPES } from '../@commons'
import { formatToDateAndHour } from 'utils/time'

export class B2BOrderForm extends React.Component {
  getFormBadges = order => {
    const isNotSent = order.id && order.isPhysical && !order.markedToSendAt
    const isSending =
      order.id &&
      order.isPhysical &&
      order.markedToSendAt &&
      order.nbBoxesSent < order.giftBoxNumber
    const isSent =
      order.id &&
      order.isPhysical &&
      order.markedToSendAt &&
      order.nbBoxesSent === order.giftBoxNumber

    const badges = []

    if (!this.props.isNew) {
      badges.push(getOrderB2BBadgeProps(order.status))
      if (isNotSent) badges.push({ label: 'Non expédiée', warning: true })
      if (isSending) badges.push({ label: "En cours d'expédition", info: true })
      if (isSent) badges.push({ label: 'Expédiée' })
    }

    return badges
  }

  onAddressChange = value => {
    const address = { ...this.props.order.address, ...value }
    this.props.onOrderChange({ address: address })
  }

  onCategoryChange = id => {
    const cat = this.props.categories.find(c => c.id === id)
    this.props.onOrderChange({
      category: { id: parseInt(cat.id), name: cat.value },
    })
  }

  render() {
    const {
      order,
      categories,
      edit,
      isNew,
      isDuplicated,
      validate,
      customMessageUrl,
      onOrderChange,
      onAttachmentsChange,
    } = this.props

    const deliveryMethodsOptions = DELIVERY_METHODS.map(type => ({
      ...type,
      value: type.value.toString(),
    }))
    const messageTypesOptions = MESSAGE_TYPES.map(t => ({
      ...t,
      value: t.value.toString(),
    }))
    const addressTypesOptions = ADDRESS_TYPES.map(t => ({
      ...t,
      value: t.value.toString(),
    }))

    const hasBeenActived = !!order.activatedAt
    const isPhysicalOrder = order.deliveryMethod === 1
    const isUniqueAddress = order.addressType == 1
    const isCustomAddress = order.addressType == 2
    const isStandardMessage = order.messageType == 1
    const isCustomMessage = order.messageType == 2
    const isExisting = !!order.id && !isNew

    return (
      <Form
        innerRef={this.props.innerRef}
        title={this.props.title}
        subTitle={
          order.createdAt && !isNew
            ? formatToDateAndHour(order.createdAt)
            : null
        }
        badges={this.getFormBadges(order)}
      >
        <FormGroup title={'Référence'}>
          <FormSection>
            <TextFieldWithDisplay
              initialValue={order.customerCompany}
              id={'FormName'}
              label={'Nom du client'}
              edit={edit}
              onChange={v => onOrderChange({ customerCompany: v })}
              required
              validate={validate}
            />
            <TextFieldWithDisplay
              initialValue={order.reference}
              id={'FormInvoiceNumber'}
              label={'Numéro de facture'}
              edit={edit}
              onChange={v => onOrderChange({ reference: v })}
            />
          </FormSection>
        </FormGroup>
        <FormGroup title={'Commande'}>
          <FormSection gutter>
            <TextFieldWithDisplay
              initialValue={
                order.giftBoxNumber ? order.giftBoxNumber.toString() : ''
              }
              id={'FormGiftBox'}
              edit={edit}
              min={1}
              label={'Nombre de coffrets cadeaux'}
              type={'number'}
              required
              validate={validate && !isExisting}
              disabled={isExisting}
              focused={isDuplicated}
              onChange={v => onOrderChange({ giftBoxNumber: parseInt(v) })}
            />

            <RadioListWithDisplay
              value={order.deliveryMethod?.toString()}
              id={`FormDelivery`}
              options={deliveryMethodsOptions}
              label={'Type'}
              edit={edit}
              required
              inline
              validate={validate && !isExisting}
              disabled={isExisting}
              onChange={v => onOrderChange({ deliveryMethod: parseInt(v) })}
            />
          </FormSection>
          <FormSection>
            <TextFieldWithDisplay
              initialValue={order.giftNumber ? order.giftNumber.toString() : ''}
              label={'Nombre de cadeaux'}
              id={'FormGiftNumber'}
              min={1}
              max={6}
              type={'number'}
              edit={edit}
              required
              validate={validate && !isExisting}
              disabled={isExisting}
              onChange={v => onOrderChange({ giftNumber: parseInt(v) })}
            />
            <SelectboxWithDisplay
              initialValue={order.category?.id.toString()}
              label={'Produit'}
              id={'FormProducts'}
              values={categories}
              edit={edit}
              required
              validate={validate && !isExisting}
              disabled={isExisting}
              onChange={v => this.onCategoryChange(v)}
            />
          </FormSection>
        </FormGroup>

        {isPhysicalOrder && (
          <FormGroup title={'Livraison'}>
            <FormSection gutter={isUniqueAddress || (isCustomAddress && edit)}>
              <RadioListWithDisplay
                value={order.addressType?.toString()}
                id={`FormAddressType`}
                label={'Mode de livraison'}
                options={addressTypesOptions}
                edit={edit}
                inline
                required
                validate={validate && !hasBeenActived}
                disabled={hasBeenActived}
                onChange={v => onOrderChange({ addressType: parseInt(v) })}
              />
            </FormSection>

            {isUniqueAddress && (
              <div>
                <FormSection gutter>
                  <TextFieldWithDisplay
                    initialValue={order?.address.company}
                    id={'FormAddressCompany'}
                    label={"Nom de l'entreprise"}
                    edit={edit}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ company: v })}
                  />
                </FormSection>
                <FormSection gutter>
                  <TextFieldWithDisplay
                    initialValue={order?.address.lastName}
                    id={'FormAddressLastName'}
                    label={'Nom de la personne'}
                    edit={edit}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ lastName: v })}
                  />
                  <TextFieldWithDisplay
                    initialValue={order?.address.firstName}
                    id={'FormAddressFirstName'}
                    label={'Prénom de la personne'}
                    edit={edit}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ firstName: v })}
                  />
                </FormSection>
                <FormSection gutter>
                  <TextFieldWithDisplay
                    initialValue={order?.address.address1}
                    id={'FormAddressAddress1'}
                    label={'Adresse'}
                    edit={edit}
                    required={isUniqueAddress}
                    validate={validate && !hasBeenActived}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ address1: v })}
                  />
                </FormSection>
                <FormSection gutter>
                  <TextFieldWithDisplay
                    initialValue={order?.address.address2}
                    id={'FormAddressAddress2'}
                    label={"Complément d'adresse"}
                    edit={edit}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ address2: v })}
                  />
                </FormSection>
                <FormSection>
                  <TextFieldWithDisplay
                    initialValue={order?.address.zipCode}
                    id={'FormAddressZipCode'}
                    label={'Code postal'}
                    edit={edit}
                    required={isUniqueAddress}
                    validate={validate && !hasBeenActived}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ zipCode: v })}
                  />
                  <TextFieldWithDisplay
                    initialValue={order?.address.city}
                    id={'FormAddressity'}
                    label={'Ville'}
                    edit={edit}
                    required={isUniqueAddress}
                    validate={validate && !hasBeenActived}
                    disabled={hasBeenActived}
                    onChange={v => this.onAddressChange({ city: v })}
                  />
                </FormSection>
              </div>
            )}

            {edit && isCustomAddress && !hasBeenActived && (
              <div>
                <FormSection>
                  <FileUpload
                    showName
                    accept={[
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]}
                    onChange={v =>
                      onAttachmentsChange({ customAddress: v.content })
                    }
                  >
                    Charger le fichier XLS
                  </FileUpload>
                </FormSection>
                <FormSection>
                  <Link to={this.props.addressTemplateUrl} external>
                    Télécharger le template
                  </Link>
                </FormSection>
              </div>
            )}
          </FormGroup>
        )}

        <FormGroup title={"Message d'accompagnement"}>
          <FormSection gutter={isStandardMessage || isCustomMessage}>
            <RadioListWithDisplay
              value={order.messageType?.toString()}
              label={'Type de message'}
              id={`FormMessageType`}
              edit={edit}
              options={messageTypesOptions}
              inline
              required
              validate={validate && !hasBeenActived}
              disabled={hasBeenActived}
              onChange={v => onOrderChange({ messageType: parseInt(v) })}
            />
          </FormSection>

          {isStandardMessage && (
            <FormSection>
              <TextAreaWithDisplay
                initialValue={order.message}
                label={'Message'}
                id={`FormMessage`}
                edit={edit}
                rows={4}
                maxLength={400}
                autoResize={true}
                required={isStandardMessage}
                validate={validate && !hasBeenActived}
                disabled={hasBeenActived}
                onChange={v => onOrderChange({ message: v })}
              ></TextAreaWithDisplay>
            </FormSection>
          )}

          {isCustomMessage && (
            <div>
              {edit && !hasBeenActived && (
                <FormSection>
                  <FileUpload
                    showName
                    accept={['application/pdf']}
                    required={
                      isCustomMessage &&
                      !isDuplicated &&
                      !order.isActive &&
                      !order.hasCustomMessage
                    }
                    validate={validate && !order.isActive}
                    onChange={v =>
                      onAttachmentsChange({ customMessage: v.content })
                    }
                  >
                    Sélectionnez un fichier PDF
                  </FileUpload>
                </FormSection>
              )}
              {isExisting && order.hasCustomMessage && (
                <FormSection>
                  <Link to={customMessageUrl} external>
                    Télécharger le fichier PDF existant
                  </Link>
                </FormSection>
              )}
            </div>
          )}
        </FormGroup>
      </Form>
    )
  }
}

B2BOrderForm.propTypes = {
  innerRef: PropTypes.object,
  title: PropTypes.string,
  edit: PropTypes.bool,
  isNew: PropTypes.bool,
  customMessageUrl: PropTypes.string,
  order: PropTypes.shape({
    address: PropTypes.shape({
      company: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    addressType: PropTypes.number,
    canDelete: PropTypes.bool,
    canSend: PropTypes.bool,
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    customerCompany: PropTypes.string,
    deliveryMethod: PropTypes.number,
    deliveryMethodLabel: PropTypes.string,
    giftBoxNumber: PropTypes.number,
    giftNumber: PropTypes.number,
    hasCustomMessage: PropTypes.bool,
    hasIndividualAddresses: PropTypes.bool,
    id: PropTypes.number,
    isPhysical: PropTypes.bool,
    messageType: PropTypes.number,
    nbBoxesSent: PropTypes.number,
    reference: PropTypes.string,
    message: PropTypes.string,
    status: PropTypes.string,
  }),
  attachments: PropTypes.shape({
    customAddress: PropTypes.string,
    customMessage: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onOrderChange: PropTypes.func,
  onAttachmentsChange: PropTypes.func,
  addressTemplateUrl: PropTypes.string,
}
