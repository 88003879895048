import React from 'react'
import { withRouter } from 'hocs/withRouter'
import {
  LayoutFixed,
  Actions,
  Button,
  Modal,
  IconActionClose,
  IconExport,
  IconCopy,
  IconPlay,
  IconTruck,
} from 'ui'
import { AdminNavigation } from 'features/admin/components'
import { waitForApi } from 'utils/api'
import {
  getB2BOrder,
  getCustomMessageUrl,
  getAddressTemplateUrl,
  updateB2BOrder,
  activateB2BOrder,
  closeB2BOrder,
  exportCodes,
  exportBoxes,
  exportReviews,
  sendB2BOrder,
  deleteOrder,
} from 'services/orders'
import {
  B2BOrderForm,
  ExportB2BModal,
  ActivateB2BModal,
  B2BOrderSatisfaction,
  B2BOrderTracking,
  B2BOrderActivation,
} from '../components'
import { NotificationManager } from 'services/notifications'
import { resolvesAfter } from 'utils/promise'
import PropTypes from 'prop-types'
import { sendData } from 'download'
import styles from './B2BItem.module.scss'

const FORM_INVALID = 'Merci de compléter tous les champs requis.'
const FORM_CREATE = 'La commande a été créée'
const FORM_EDIT = 'La commande a été modifiée'
const FORM_ENABLE = 'La commande a été activée'
const FORM_SENT = 'La commande va être transmise pour expédition'
const FORM_DISABLE = 'La commande a été clôturée'

const ORDER_REMOVED = 'La commande a été supprimée'
const CODES_EXPORTED = 'Les codes ont été exportés'
const BOXES_EXPORTED =
  "L'export est en cours. Vous recevrez un email lorsque le zip sera prêt à être téléchargé"
const REVIEWS_EXPORTED = 'Les avis ont été exportés'

class B2BItem extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      loading: false,
      validate: false,
      activateModalOpened: false,
      activationDates: null,
      deleteModalOpened: false,
      exportModalOpened: false,
      edit: props.edit,
      order: {},
      attachments: {},
      categories: [],
    }
  }

  loadData = () => {
    const orderId = this.getOrderId()
    return waitForApi(getB2BOrder(orderId))
  }

  isFormValid = () => {
    const isValid = [...this.formRef.current].reduce(
      (acc, value) => acc && value.checkValidity(),
      true
    )

    if (!isValid) {
      NotificationManager.addError(FORM_INVALID)
      this.setState({ validate: true })
    }

    return isValid
  }

  getOrderId = () => {
    return parseInt(this.props.params.id)
  }

  getOriginalOrderId = () => {
    return this.isNew() && this.getOrderId() ? this.getOrderId() : 0
  }

  isNew = () => {
    return /\/new/.test(this.props.location.pathname)
  }

  isDuplicated = () => {
    return this.isNew() && this.getOriginalOrderId() > 0
  }

  getTitle = () => {
    if (this.isNew()) {
      return 'Nouvelle commande'
    } else {
      return `Commande #${this.getOrderId()}`
    }
  }

  updateOrder = () => {
    const body = {
      order: this.state.order,
      originalOrderId: this.getOriginalOrderId(),
      attachments: this.state.attachments,
    }

    this.setState({ loading: true })

    return resolvesAfter(updateB2BOrder(body))
      .then(this.onUpdateExceptions)
      .then(this.onUpdateSuccess)
      .catch(this.onError)
  }

  sendOrder = () => {
    if (
      window.confirm(
        'Etes-vous sur de vouloir expédier tous les coffrets pour cette commande ?'
      )
    ) {
      return resolvesAfter(sendB2BOrder(this.state.order.id))
        .then(this.onUpdateExceptions)
        .then(this.onSendSuccess)
        .catch(this.onError)
    }
  }

  activateOrder = () => {
    return resolvesAfter(
      activateB2BOrder({
        id: this.state.order.id,
        ...this.state.activationDates,
      })
    )
      .then(this.onUpdateExceptions)
      .then(this.onEnableSuccess)
      .catch(this.onError)
  }

  closeOrder = () => {
    if (
      window.confirm(
        'Etes-vous sur de vouloir désactiver tous les coffrets pour cette commande ? ATTENTION : toute désactivation est définitive.'
      )
    ) {
      return resolvesAfter(closeB2BOrder(this.state.order.id))
        .then(this.onUpdateExceptions)
        .then(this.onCloseSuccess)
        .catch(this.onError)
    }
  }

  deleteOrder = () => {
    return waitForApi(deleteOrder(this.state.order.id))
      .then(this.onDeleteOrderSuccess)
      .then(this.closeDeleteModal)
      .catch(this.onError)
  }

  exportCodes = () => {
    return waitForApi(exportCodes(this.state.order.id))
      .then(this.onExportCodesSuccess)
      .then(this.closeExportModal)
      .catch(this.onError)
  }

  exportBoxes = () => {
    return waitForApi(exportBoxes(this.state.order.id))
      .then(this.onExportBoxesSuccess)
      .then(this.closeExportModal)
      .catch(this.onError)
  }

  exportReviews = () => {
    return waitForApi(exportReviews(this.state.order.id))
      .then(this.onExportReviewsSuccess)
      .then(this.closeExportModal)
      .catch(this.onError)
  }

  back = () => {
    this.props.navigate(-1)
  }

  cancel = () => {
    if (this.isNew()) {
      this.back()
    } else {
      this.setState({ loading: true })
      this.loadData().then(data => {
        this.onLoadDataSuccess(data)
        this.setState({ edit: false })
      })
    }
  }

  closeDeleteModal = () => this.setState({ deleteModalOpened: false })
  closeExportModal = () => this.setState({ exportModalOpened: false })
  closeActivateModal = () => this.setState({ activateModalOpened: false })

  display = id => {
    this.props.navigate(`/a/orders/b2b/${id}`, {
      replace: true,
    })
  }

  edit = () => this.setState({ edit: true })

  duplicate = () => {
    this.props.navigate(`/a/orders/b2b/new/${this.state.order.id}`)
  }

  save = e => {
    e.preventDefault()
    if (this.isFormValid()) this.updateOrder()
  }

  onOrderChange = value => {
    this.setState({ order: { ...this.state.order, ...value } })
  }

  onAttachmentsChange = value => {
    const attachments = { ...this.state.attachments, ...value }
    this.setState({ attachments })
  }

  onUpdateExceptions = data => {
    if (data.exceptions?.length > 0) {
      data.exceptions.map(e => NotificationManager.addError(e))
      return Promise.reject()
    }
    return Promise.resolve(data)
  }

  onError = error => {
    this.setState({ loading: false })
    if (error != undefined) NotificationManager.addError(error)
  }

  onLoadDataSuccess = data => {
    const order = { ...data.result.order }
    const categories = data.result.categories.map(c => ({
      id: c.id.toString(),
      value: c.name,
    }))

    if (this.isDuplicated()) {
      order.id = 0
      order.giftBoxNumber = 0
      order.giftNumber = 0
      order.reference = ''
      order.status = 'inactive'
      order.activatedAt = null
      order.activatedTo = null
    }

    this.setState({
      order: order,
      categories: categories,
      stats: { ...data.result.stats },
      loading: false,
    })
  }

  onUpdateSuccess = data => {
    NotificationManager.addSuccess(this.isNew() ? FORM_CREATE : FORM_EDIT)
    if (this.isNew()) {
      this.display(data.result.orderID)
    } else {
      this.setState({ loading: false, edit: false })
    }
  }

  onSendSuccess = () => {
    NotificationManager.addSuccess(FORM_SENT)
    this.setState({
      loading: true,
    })
    this.loadData().then(this.onLoadDataSuccess)
  }

  onEnableSuccess = () => {
    NotificationManager.addSuccess(FORM_ENABLE)
    this.setState({
      loading: true,
      activateModalOpened: false,
      activationDates: null,
    })
    this.loadData().then(this.onLoadDataSuccess)
  }

  onCloseSuccess = () => {
    NotificationManager.addSuccess(FORM_DISABLE)
    this.setState({
      loading: true,
    })
    this.loadData().then(this.onLoadDataSuccess)
  }

  onDeleteOrderSuccess = () => {
    NotificationManager.addSuccess(ORDER_REMOVED)
    this.back()
  }

  onExportCodesSuccess = data => {
    sendData(data.result.file, 'text/csv', `${data.result.name}.csv`)
    NotificationManager.addSuccess(CODES_EXPORTED)
  }

  onExportBoxesSuccess = () => {
    NotificationManager.addSuccess(BOXES_EXPORTED)
  }

  onExportReviewsSuccess = data => {
    sendData(data.result.file, 'text/csv', `${data.result.name}.csv`)
    NotificationManager.addSuccess(REVIEWS_EXPORTED)
  }

  getLeftActions = () => {
    if (this.state.edit) {
      return [
        <Button light onClick={this.cancel}>
          Annuler
        </Button>,
      ]
    } else {
      return [
        <Button light onClick={this.back}>
          Retour
        </Button>,
      ]
    }
  }

  getRightActions = () => {
    const { order, edit } = this.state
    let actions = []

    if (!edit) {
      actions.push(
        ...[
          <Button
            light
            hideLabelOnMobile
            onClick={() => this.setState({ exportModalOpened: true })}
            icon={<IconExport />}
          >
            Exporter
          </Button>,
          <Button
            light
            hideLabelOnMobile
            onClick={this.duplicate}
            icon={<IconCopy />}
          >
            Dupliquer
          </Button>,
        ]
      )
    }

    if (!edit && !this.isNew() && order.canSend) {
      actions.push(
        <Button
          light
          hideLabelOnMobile
          onClick={this.sendOrder}
          icon={<IconTruck />}
        >
          Expédier
        </Button>
      )
    }

    if (
      !edit &&
      !this.isNew() &&
      order.status === 'inactive' &&
      order.reference !== ''
    ) {
      actions.push(
        <Button
          light
          hideLabelOnMobile
          onClick={() => this.setState({ activateModalOpened: true })}
          icon={<IconPlay />}
        >
          Activer
        </Button>
      )
    }

    if (!edit && !this.isNew() && order.status === 'active') {
      actions.push(
        <Button
          light
          hideLabelOnMobile
          onClick={this.closeOrder}
          icon={<IconActionClose />}
        >
          Clôturer
        </Button>
      )
    }

    if (edit) {
      actions.push(
        <Button primary onClick={this.save}>
          Enregistrer
        </Button>
      )
    } else {
      actions.push(
        <Button primary onClick={this.edit}>
          Modifier
        </Button>
      )
    }

    return actions
  }

  renderModals() {
    const { activateModalOpened, exportModalOpened, deleteModalOpened, order } =
      this.state

    return (
      <>
        <Modal
          opened={activateModalOpened}
          title={'Activer une commande'}
          closable
          onClose={this.closeActivateModal}
          actions={[
            <Button onClick={this.closeActivateModal}>Annuler</Button>,
            <Button primary onClick={this.activateOrder}>
              Activer
            </Button>,
          ]}
        >
          <ActivateB2BModal
            order={order}
            onChange={activationDates => this.setState({ activationDates })}
          />
        </Modal>
        <Modal
          opened={exportModalOpened}
          title={'Exporter'}
          closable
          onClose={this.closeExportModal}
        >
          <ExportB2BModal
            order={order}
            onExportCodes={this.exportCodes}
            onExportBoxes={this.exportBoxes}
            onExportReviews={this.exportReviews}
          />
        </Modal>
        <Modal
          opened={deleteModalOpened}
          title={'⚠️ Suppression de commande'}
          closable
          onClose={this.closeDeleteModal}
          actions={[
            <Button onClick={this.deleteOrder}>Supprimer la commande</Button>,
            <Button primary onClick={this.closeDeleteModal}>
              Annuler
            </Button>,
          ]}
        >
          <div>
            Cette action est <b>irréversible</b>. Êtes-vous sûr de vouloir
            supprimer cette commande ?
          </div>
        </Modal>
      </>
    )
  }

  render() {
    const { edit, order } = this.state

    return (
      <LayoutFixed
        title={this.getTitle()}
        nav={<AdminNavigation title={'Commandes'} />}
        loadData={this.loadData}
        onDataLoaded={this.onLoadDataSuccess}
        loading={this.state.loading}
        offscreen={this.renderModals()}
        actions={
          <Actions
            left={this.getLeftActions()}
            right={this.getRightActions()}
          />
        }
      >
        <B2BOrderForm
          innerRef={this.formRef}
          title={this.getTitle()}
          order={order}
          attachments={this.state.attachments}
          categories={this.state.categories}
          edit={edit}
          validate={this.state.validate}
          customMessageUrl={getCustomMessageUrl(this.getOrderId())}
          onOrderChange={this.onOrderChange}
          onAttachmentsChange={this.onAttachmentsChange}
          addressTemplateUrl={getAddressTemplateUrl()}
          isNew={this.isNew()}
          isDuplicated={this.isDuplicated()}
        />

        {!edit && order.status !== 'inactive' && (
          <B2BOrderActivation order={order} />
        )}

        {!edit && order.trackingItems && <B2BOrderTracking order={order} />}

        {!edit && ['active', 'closed'].includes(order.status) && (
          <B2BOrderSatisfaction stats={this.state.stats} order={order} />
        )}

        {!edit && this.state.order.canDelete && (
          <div className={styles.delete}>
            <Button
              onClick={() => this.setState({ deleteModalOpened: true })}
              error
              light
            >
              Supprimer la commande
            </Button>
          </div>
        )}
      </LayoutFixed>
    )
  }
}

export const FeatureAdminOrdersB2BItem = withRouter(B2BItem)

FeatureAdminOrdersB2BItem.prototype = {
  edit: PropTypes.bool,
}

FeatureAdminOrdersB2BItem.defaultProps = {
  edit: false,
}
