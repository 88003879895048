import api from 'utils/api'

export const ShippingStates = {
  received: {
    value: 'received',
    singular: 'Reçue',
    plural: 'Reçues',
  },
  accepted: {
    value: 'accepted',
    singular: 'Acceptée',
    plural: 'Acceptées',
  },
  sent: {
    value: 'sent',
    singular: 'Expédiée',
    plural: 'Expédiées',
  },
  forthcoming: {
    value: 'forthcoming',
    singular: 'À venir',
    plural: 'À venir',
  },
  canceled: {
    value: 'canceled',
    singular: 'Annulée',
    plural: 'Annulées',
  },
  delivered: {
    value: 'delivered',
    singular: 'Réceptionnée',
    plural: 'Réceptionnées',
  },
}

export const acceptAllShippings = (uniqueKey, query) =>
  api.post('api/suppliers/shippings/acceptAll', {
    uniqueKey,
    query,
  })

export const sendAllShipping = (uniqueKey, query) =>
  api.post('api/suppliers/shippings/sendAll', {
    uniqueKey,
    query,
  })

export const exportShipping = (uniqueKey, status) =>
  api.post('api/suppliers/shippings/export', {
    uniqueKey,
    status,
  })

export const exportHistory = (uniqueKey, year, month) =>
  api.post('api/suppliers/shippings/history/export', {
    uniqueKey,
    year,
    month,
  })

export const getShippings = body =>
  api.post('api/suppliers/shippings/get', body)

export const acceptShipping = (uniqueKey, id) =>
  api.post('api/suppliers/shippings/accept', { uniqueKey, id })

export const sendShipping = (uniqueKey, id) =>
  api.post('api/suppliers/shippings/send', { uniqueKey, id })

export const addTrackingShipping = (
  uniqueKey,
  shippingId,
  trackingRef,
  shipperId
) =>
  api.post('api/suppliers/shippings/addtracking', {
    uniqueKey,
    shippingId,
    trackingRef,
    shipperId,
  })

export const printTrackingShipping = (uniqueKey, shippingId, weight) =>
  api.post(
    `api/suppliers/shippings/generateColissimo?UniqueKey=${uniqueKey}&ShippingID=${shippingId}&Weight=${weight}`
  )

export const getShippingStats = body =>
  api.post('api/suppliers/shippings/stats', body)

export const getShippingHistory = body =>
  api.post('api/suppliers/shippings/history/get', body)

export const importChronopostFile = (uniqueKey, content) =>
  api.post('api/suppliers/shippings/importChronopost', {
    uniqueKey,
    content,
  })
