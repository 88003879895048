import React from 'react'
import PropTypes from 'prop-types'
import styles from './PrepaFacileForm.module.scss'
import { FileUpload } from 'ui'

const IMPORT_FILE_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export class PrepaFacileForm extends React.Component {
  onChange(value) {
    this.props.onSubmit({ ...value })
  }

  fileUploadTitle = () => {
    return this.props.isFileSelected
      ? 'Importer un nouveau fichier PrépaFacile'
      : 'Importer un fichier PrépaFacile'
  }

  render() {
    return (
      <form className={styles.form}>
        <FileUpload
          onChange={value => this.onChange(value)}
          accept={[IMPORT_FILE_MIME_TYPE]}
          primary
        >
          {this.fileUploadTitle()}
        </FileUpload>
      </form>
    )
  }
}

PrepaFacileForm.propTypes = {
  isFileSelected: PropTypes.bool,
  file: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
}
