import React from 'react'
import styles from './SepaForm.module.scss'
import { FileUpload } from 'ui'

const IMPORT_FILE_MIME_TYPE = 'text/csv'

export function SepaForm(props) {
  function onChange(value) {
    props.onExport({ ...value })
  }

  return (
    <form className={styles.form}>
      <FileUpload
        onChange={value => onChange(value)}
        accept={[IMPORT_FILE_MIME_TYPE]}
        primary
      >
        Générer un fichier de paiement SEPA
      </FileUpload>
    </form>
  )
}
