import React from 'react'
import {
  Grid,
  LayoutFixed,
  MenuSecondary,
  Modal,
  Button,
  IconPlus,
  OrderB2BAdminCard,
} from 'ui'
import { AdminActions, AdminNavigation } from 'features/admin/components'
import { MENU_LINKS } from 'features/admin/orders/@commons'

import {
  exportCodes,
  getB2BOrders,
  exportBoxes,
  exportReviews,
} from 'services/orders'
import { NotificationManager } from 'services/notifications'
import { getRouteParam, setRouteParam } from 'utils/route'
import { ExportB2BModal } from '../components'
import { waitForApi } from 'utils/api'
import { sendData } from 'download'
import { withRouter } from 'hocs/withRouter'

const CODES_EXPORTED = 'Les codes ont été exportés'
const BOXES_EXPORTED =
  "L'export est en cours. Vous recevrez un email lorsque le zip sera prêt à être téléchargé"

class B2BList extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      result: { orders: [], products: [] },
      exportModalOpened: false,
      loading: false,
      exportingOrder: {},
    }
  }

  loadData = () => {
    const { status, deliveryMethod, sending, sent } = this.props
    const page = parseInt(getRouteParam(this.props.location, 'page')) || 1
    const query = this.query()
    return waitForApi(
      getB2BOrders({ status, page, query, deliveryMethod, sending, sent })
    )
  }

  onLoadDataSuccess = data => {
    this.setState({ ...data, loading: false })
  }

  onFilter = filter => {
    let location = setRouteParam(this.props.location, 'query', filter)
    location = setRouteParam(location, 'page', 1)
    this.props.navigate(location)
  }

  query() {
    return getRouteParam(this.props.location, 'query') || ''
  }

  onExport = id => {
    const order = this.state.result.orders.find(r => r.id === id)

    const exportingOrder = {
      id: order.id,
      isPhysical: order.isPhysical,
      activatedAt: order.activatedAt,
    }

    this.setState({
      exportModalOpened: true,
      exportingOrder,
    })
  }

  onExportClose = () => {
    this.setState({ exportModalOpened: false })
  }

  onExportCodes = id => {
    this.setState({ loading: true })

    return waitForApi(exportCodes(id))
      .then(this.onExportCodesSuccess)
      .then(this.onExportClose)
      .catch(this.onError)
  }

  onExportReviews = id => {
    this.setState({ loading: true })

    return waitForApi(exportReviews(id))
      .then(this.onExportReviewsSuccess)
      .then(this.onExportClose)
      .catch(this.onError)
  }

  onExportCodesSuccess = data => {
    this.setState({ loading: false })
    sendData(data.result.file, 'text/csv', `${data.result.name}.csv`)
    NotificationManager.addSuccess(CODES_EXPORTED)
  }

  onExportReviewsSuccess = data => {
    this.setState({ loading: false })
    sendData(data.result.file, 'text/csv', `${data.result.name}.csv`)
    NotificationManager.addSuccess(CODES_EXPORTED)
  }

  onExportBoxes = id => {
    this.setState({ loading: true })

    return waitForApi(exportBoxes(id))
      .then(this.onExportBoxesSuccess)
      .then(this.onExportClose)
      .catch(this.onError)
  }

  onExportBoxesSuccess = () => {
    this.setState({ loading: false })
    NotificationManager.addSuccess(BOXES_EXPORTED)
  }

  onError = error => this.setState({ error, loading: false })

  create = id => {
    this.props.navigate(`/a/orders/b2b/new`)
  }

  duplicate = id => {
    this.props.navigate(`/a/orders/b2b/new/${id}`)
  }

  renderModals() {
    const { exportModalOpened, exportingOrder } = this.state
    return (
      <Modal
        opened={exportModalOpened}
        title={'Exporter'}
        closable
        onClose={this.onExportClose}
      >
        <ExportB2BModal
          order={exportingOrder}
          onExportCodes={this.onExportCodes}
          onExportBoxes={this.onExportBoxes}
          onExportReviews={this.onExportReviews}
        />
      </Modal>
    )
  }

  getActions() {
    const actions = []
    actions.push(
      <Button
        primary
        onClick={this.create}
        icon={<IconPlus />}
        hideLabelOnMobile
      >
        Créer
      </Button>
    )
    return actions
  }

  render() {
    const { title } = this.props
    const {
      result: { orders },
      pagination,
      loading,
    } = this.state

    return (
      <LayoutFixed
        title={title}
        nav={<AdminNavigation title={'Commandes'} />}
        offscreen={this.renderModals()}
        menu={<MenuSecondary links={MENU_LINKS} />}
        loadData={this.loadData}
        onDataLoaded={this.onLoadDataSuccess}
        loading={loading}
        actions={
          <AdminActions
            total={pagination?.nbItems}
            onFilter={this.onFilter}
            query={this.query()}
            actions={this.getActions()}
          />
        }
      >
        <Grid
          cols={1}
          items={orders?.map(o => (
            <OrderB2BAdminCard
              key={o.id}
              order={o}
              onDuplicateOrder={this.duplicate}
              onExport={this.onExport}
            />
          ))}
        />
      </LayoutFixed>
    )
  }
}

export const FeatureAdminOrdersB2BList = withRouter(B2BList)
