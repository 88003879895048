import { useEffect, useRef, useState } from 'react'
import { Button, Divider, Grid, Modal } from 'ui'
import {
  getAddressItems,
  getInfoItems,
  getStreetItems,
  getCountryItems,
} from './coords'

export const FormCoordinatesModal = ({
  id,
  coords: initialCoords,
  onCancel,
  onSubmit,
}) => {
  const [coords, setCoords] = useState(initialCoords)
  const [isValid, setIsValid] = useState(false)
  const [emailConfirm] = useState(coords?.email)
  const refForm = useRef(null)

  useEffect(() => {
    // Make it asynchronous 'cause checkValidity returns false on first load
    setTimeout(() => {
      setFormValidity()
    }, 0)
  }, [coords])

  const setFormValidity = () => {
    const isValid = refForm?.current?.checkValidity() || false
    setIsValid(isValid)
  }

  const onFieldChange = value => {
    setCoords({ ...coords, ...value })
    setFormValidity()
  }

  const cancel = () => {
    onCancel()
    setCoords(initialCoords)
  }

  const submit = () => {
    onSubmit(coords)
    setCoords(initialCoords)
  }

  return (
    <Modal
      opened={!!id}
      title={'Veuillez saisir vos nouvelles coordonnées'}
      closable
      leftActions={[<Button onClick={cancel}>Annuler</Button>]}
      actions={[
        <Button primary disabled={!isValid} onClick={submit}>
          Valider
        </Button>,
      ]}
      onClose={cancel}
    >
      {coords && (
        <form ref={refForm}>
          <div>
            <Grid
              cols={1}
              s={2}
              items={getInfoItems(
                coords,
                emailConfirm,
                onFieldChange,
                setFormValidity
              )}
            />
            <Divider />
            <Grid cols={1} items={getStreetItems(coords, onFieldChange)} />
            <Divider />
            <Grid
              cols={1}
              s={2}
              items={getAddressItems(coords, onFieldChange)}
            />
            <Divider />
            <Grid cols={1} items={getCountryItems(coords, onFieldChange)} />
          </div>
        </form>
      )}
    </Modal>
  )
}
